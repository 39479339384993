import constants from '@/constants'

export const getTrackNameForImportCartEvents = (eventName, isRetailer) => {
  const trackNames = {
    brand: {
      'file-select': constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_SELECTS_FILE_FOR_IMPORT_CART_MODAL,
      'file-remove': constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_REMOVES_FILE_FROM_IMPORT_CART_MODAL,
      'confirm-button-click': constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_CLICKS_CONFIRM_BUTTON_OF_IMPORT_CART_MODAL,
      'file-import-failed': constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_IMPORT_FILE_FAILED,
    },
    retailer: {
      'file-select': constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_SELECTS_FILE_FOR_IMPORT_CART_MODAL,
      'file-remove': constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_REMOVES_FILE_FROM_IMPORT_CART_MODAL,
      'confirm-button-click': constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_CLICKS_CONFIRM_BUTTON_OF_IMPORT_CART_MODAL,
      'file-import-failed': constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_IMPORT_FILE_FAILED,
    },
  }

  return isRetailer ? trackNames.retailer[eventName] : trackNames.brand[eventName]
}

export const getTrackNameForAddressModal = (modalName, actionType) => {
  const trackNames = {
    'modal-add-billing': {
      click: constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_CLICKS_ADD_BILLING_ADDRESS_BUTTON,
      close: constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_CLOSES_ADD_BILLING_ADDRESS_MODAL,
    },
    'modal-add-shipping': {
      click: constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_CLICKS_ADD_SHIPPING_ADDRESS_BUTTON,
      close: constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_CLOSES_ADD_SHIPPING_ADDRESS_MODAL,
    },
  }

  return trackNames[modalName][actionType]
}
