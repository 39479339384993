<script>
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import analytics from '@/@core/utils/analytics'
import { formatObject } from '@/@core/utils/utils'
import constants from '@/constants'
import store from '@/store'
import { UPDATE_ENTITY } from '@/store/modules/auth.module'

const { ADDRESSES_TYPE } = c

export default {
  data() {
    return {
      billingAddress: [],
      shippingAddress: [],
      ADDRESSES_TYPE,
      userId: null,
      selectAddressModalId: '',
    }
  },
  computed: {
    isCheckout() {
      return this.$route.name === 'checkout'
    },
  },
  methods: {
    getBillingAndShippingAddress(addresses, disabled) {
      addresses.forEach(add => {
        if (add) {
          add.isEditEnabled = disabled ? false : add?.addressType === ADDRESSES_TYPE.BILLING
          add.isSaving = false
        }
      })
      this.billingAddress = addresses.filter(item => item?.addressType === ADDRESSES_TYPE.BILLING)
      this.shippingAddress = addresses.filter(item => item?.addressType === ADDRESSES_TYPE.SHIPPING)
    },
    addOrUpdateAddress(address) {
      address.isSaving = true
      const addresses = JSON.parse(JSON.stringify(this.addresses))
      const index = addresses.findIndex(add => add._id === address._id)
      if (index !== -1) {
        addresses[index] = address
      } else {
        addresses.push(address)
      }
      this.updateAddress(addresses, 'Address updated successfully', address)
      const payload = {
        addresses,
      }
      if (this.isCheckout) {
        analytics.track(constants.TRACKS.ACTIONS.RETAILER_ADDS_NEW_ADDRESS_IN_CART)
      }
      else if (address._id) {
        analytics.track(
          constants.TRACKS.ACTIONS.CREATE_ADDRESS_PROFILE,
          formatObject(payload),
        )
      }
      else {
        analytics.track(
          constants.TRACKS.ACTIONS.UPDATE_ADDRESS_PROFILE,
          formatObject(payload),
        )
      }
    },
    setAsPrimaryAddress(addressId) {
      const addresses = JSON.parse(JSON.stringify(this.addresses))
      addresses.forEach(add => {
        add.isPrimary = false
        if (add._id === addressId || add.addressType === ADDRESSES_TYPE.BILLING) {
          add.isPrimary = true
        }
      })
      this.updateAddress(addresses, 'Primary address updated successfully')
    },
    async updateAddress(addresses, successMessage = '', addressBeingUpdated) {
      const updatedAddresses = addresses.map(address => {
          delete address.isEditEnabled
          delete address.isSaving
          delete address._id

          return address
        })
      const payload = {
        addresses: updatedAddresses,
      }
      const entityData = store.getters.entityData
      store.dispatch(UPDATE_ENTITY, {
          ...entityData,
          ...payload,
        })
        .then(() => {
          if (this.trackAddressSaveSuccess) {
            this.trackAddressSaveSuccess(addressBeingUpdated)
          }
          if (this.isCheckout) {
            this.$bvModal.hide('modal-select')
            this.$bvModal.hide('modal-add-shipping')
            this.$bvModal.hide('modal-add-billing')
          }
          this.$bvModal.hide(this.selectAddressModalId)
          apiToastSuccess(successMessage)
          if (!this.userId) {
            this.getUserProfile()
          } else {
            this.addresses = updatedAddresses
            this.loadShippingAddress()
          }
        })
        .catch(err => {
          if (this.trackAddressSaveFailed) {
            this.trackAddressSaveFailed(addressBeingUpdated, err)
          }
          apiToastWarning(err)
        })
    },
  },
}
</script>
