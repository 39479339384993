<template>
  <b-modal
    id="cart-terms"
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
    size="xl"
    modal-class="cart-terms-modal"
    @hide="$emit('on-close-terms-modal')"
  >
    <div
      class="
        pb-5-px
        border-bottom
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <div class="elements-center">
        <span class="custom-modal-heading color-dark-black">
          Terms and conditions
        </span>
      </div>
      <feather-icon
        class="float-right color-neutral-black cursor-pointer"
        icon="XIcon"
        size="24"
        @click="$bvModal.hide('cart-terms')"
      />
    </div>
    <div class="my-1">
      <div class="cart-terms-heading text-center">
        GENERAL SALE CONDITIONS
      </div>
      <div class="terms-block">
        <div
          v-for="content in cartTerms"
          :key="content.title"
        >
          <div class="cart-terms-heading">
            {{ content.title }}
          </div>
          <p
            v-for="point in content.points"
            :key="point"
          >
            {{ point }}
          </p>
        </div>
      </div>
      <div class="mt-1 d-flex justify-content-end">
        <b-button
          variant="info"
          class="mr-2"
          @click="updateTerms"
        >
          Accept
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, VBModal } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import cartTerms from './cart-terms-content'

export default {
  components: {
    BModal,
    BButton,
    FeatherIcon,
  },
  directives: {
    'v-b-modal': VBModal,
  },
  data() {
    return {
      cartTerms,
    }
  },
  emits: ['update-terms'],
  methods: {
    updateTerms() {
      this.$emit('update-terms')
      this.$bvModal.hide('cart-terms')
    },
  },
}
</script>
