<template>
  <!-- address form -->
  <div
    v-if="address"
    class="mt-1"
  >
    <validation-observer
      ref="address"
      #default="{ invalid }"
    >
      <b-row>

        <!-- Address line 1 -->
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Address Line 1"
            vid="Address Line 1"
            rules="required"
          >
            <b-form-group label="Address Line 1">
              <b-form-input
                v-model="address.addressLine1"
                :state="errors.length > 0 ? false : null"
                :class="{'disabled-input': !address.isEditEnabled}"
                :disabled="!address.isEditEnabled"
                placeholder="Enter Address Line 1"
              />
              <span
                v-if="errors.length"
                class="text-danger font-small-3"
              > {{ errors[0] }} </span>

            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Address line 2 -->
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Address Line 2"
            vid="Address Line 2"
            rules="required"
          >
            <b-form-group label="Address Line 2">
              <b-form-input
                v-model="address.addressLine2"
                :state="errors.length > 0 ? false : null"
                :class="{'disabled-input': !address.isEditEnabled}"
                :disabled="!address.isEditEnabled"
                placeholder="Enter Address Line 2"
              />
              <span
                v-if="errors.length"
                class="text-danger font-small-3"
              > {{ errors[0] }} </span>

            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- City -->
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="City"
            vid="City"
            rules="required"
          >
            <b-form-group label="City">
              <b-form-input
                v-model="address.city"
                :state="errors.length > 0 ? false : null"
                :class="{'disabled-input': !address.isEditEnabled}"
                :disabled="!address.isEditEnabled"
                placeholder="Enter City"
              />
              <span
                v-if="errors.length"
                class="text-danger font-small-3"
              > {{ errors[0] }} </span>

            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Country -->
        <b-col
          v-if="countries.length"
          md="6"
        >
          <validation-provider
            #default="{ errors }"
            name="Country"
            vid="Country"
            rules="required"
          >
            <b-form-group label="Country">
              <v-select
                v-model="address.country"
                :options="countries"
                :disabled="!address.isEditEnabled"
                placeholder="Country"
                class="country-select"
              />
              <span
                v-if="errors.length"
                class="text-danger font-small-3"
              > {{ errors[0] }} </span>

            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Zipcode -->
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Zipcode"
            vid="Zipcode"
            rules="required"
          >
            <b-form-group label="Zipcode">
              <b-form-input
                v-model="address.zipcode"
                :state="errors.length > 0 ? false : null"
                :class="{'disabled-input': !address.isEditEnabled}"
                :disabled="!address.isEditEnabled"
                placeholder="Enter Zipcode"
              />
              <span
                v-if="errors.length"
                class="text-danger font-small-3"
              > {{ errors[0] }} </span>

            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Contact name -->
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Address Contact Name"
            vid="Address Contact Name"
            rules="required"
          >
            <b-form-group
              label="Address Contact Name"
            >
              <b-form-input
                v-model="address.contactName"
                :state="errors.length > 0 ? false : null"
                :class="{'disabled-input': !address.isEditEnabled}"
                :disabled="!address.isEditEnabled"
                placeholder="Enter Address Contact Name"
              />
              <span
                v-if="errors.length"
                class="text-danger font-small-3"
              > {{ errors[0] }} </span>

            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Contact number -->
        <b-col md="6">
          <validation-provider
            name="Address Contact Number"
            vid="Address Contact Number"
            rules="required"
          >
            <b-form-group
              label="Address Contact Number"
            >
              <vue-tel-input
                v-model="address.contactNumber"
                :input-options="inputOptions"
                :class="{ 'form-control': true, 'tel-input-disabled': !address.isEditEnabled }"
                mode="international"
                :disabled="!address.isEditEnabled"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Contact Email -->
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Address Contact Email"
            vid="Address Contact Email"
            rules="required|email"
          >
            <b-form-group
              label="Address Contact Email"
            >
              <b-form-input
                v-model="address.contactEmail"
                :state="errors.length > 0 ? false : null"
                :class="{'disabled-input': !address.isEditEnabled}"
                :disabled="!address.isEditEnabled"
                placeholder="Enter Address Contact Email"
              />
              <span
                v-if="errors.length"
                class="text-danger font-small-3"
              > {{ errors[0] }} </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <div :class="{'text-left w-50': !isCheckout , 'text-center mt-1': isCheckout}">
            <b-button
              v-if="address.isEditEnabled"
              :variant="isCheckout ? 'info' : 'primary'"
              :disabled="address.isSaving || invalid"
              @click="addOrUpdateAddress(address)"
            >{{ address.isSaving ? 'Saving...' : 'Save' }}</b-button>
            <b-button
              v-if="address.isEditEnabled"
              class="ml-1"
              :variant="isCheckout ? 'outline-info' : 'outline-secondary'"
              :disabled="address.isSaving"
              @click="$emit('cancel')"
            > Cancel </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'AddressForm',
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    address: {
      type: Object,
      default: () => {},
    },
    countries: {
      type: Array,
      default: () => [],
    },
    addOrUpdateAddress: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      inputOptions: {
        id: `${this.address?._id}address-contact-number`,
      },
    }
  },
  computed: {
    isCheckout() {
      return this.$route.name === 'checkout'
    },
  },
  watch: {
    address: {
      handler(val) {
        this.$emit('on-address-data-change', val)
      },
      deep: true,
    },
  },
  emits: ['cancel'],
}
</script>
<style scoped>

.tel-input-disabled {
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0 !important
}

.tel-input-disabled:focus {
  border: 1px solid #E0E0E0 !important;
  box-shadow: none !important
}
</style>
