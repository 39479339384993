<template>
  <div
    v-if="!isLoadingCart"
    class="ecommerce-application"
  >
    <div
      v-if="directRetailers.length && !selectedDirectRetailer"
      class="mt-1 d-flex align-items-center"
    >
      Select Retailer <span class="text-danger"> * </span>
      <b-dropdown
        class="filter-dropdown w-25 ml-1"
        variant="outline-none"
        block
        no-caret
        menu-class="w-100 filter-dropdown-menu scroll-bar"
        toggle-class="filter-dropdown-toggle"
        no-flip
      >
        <template #button-content>
          <div
            class="d-flex justify-content-between"
            :class="{ 'has-value': selectedRetailer }"
          >
            <span>
              {{
                selectedRetailer
                  ? `${textTruncate(
                    selectedRetailer.entityName,
                    true,
                    25,
                    20
                  )} - ${selectedRetailer.email}`
                  : 'Select Retailer'
              }}
            </span>
            <feather-icon icon="ChevronDownIcon" />
          </div>
        </template>
        <div>
          <b-dropdown-form>
            <b-form-input
              v-model="searchText"
              placeholder="Search retailer"
            />
            <feather-icon
              icon="SearchIcon"
              class="dropdown-form-search-icon"
              size="18"
            />
          </b-dropdown-form>
        </div>
        <template v-if="allDirectRetailers.length">
          <b-dropdown-item
            v-for="option in allDirectRetailers"
            :key="option.retailerId"
            :active="option.retailerId === retailerId"
            @click="onSelectRetailer(option)"
          >
            <div>
              <span> {{ option.entityName }} - {{ option.email }} </span>
            </div>
          </b-dropdown-item>
        </template>
        <b-dropdown-item
          v-else
          disabled
        >
          No matched retailers
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      v-else
      class="direct-retailer-selected mt-1 d-flex align-items-center"
    >
      <span>Selected Retailer:</span>
      <strong>
        {{ selectedDirectRetailerTitle }}
      </strong>
    </div>
    <cart-total
      :key="reloadkey"
      :cart-products="cartProducts"
      :retailer-id="retailerId"
      :brand-notes="notesData"
      is-popup
      :modal-id="modalId"
      :get-cart-products-by-brand="getCartProductsByBrand"
      @remove-product-selected-from-brand="removeProduct"
      @update-notes="updateNotes"
      @remove-multiple-products="removeMultipleProducts"
    />
  </div>
  <div v-else>
    <div class="text-center p-4">
      <b-spinner />
    </div>
  </div>
</template>
<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import {
  formatObject,
  searchDirectRetailer,
  textTruncate,
} from '@/@core/utils/utils'
import {
  BDropdown,
  BDropdownForm,
  BDropdownItem,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { debounce } from 'lodash'
import CartTotal from './CartTotal.vue'

const { TRACKS } = constants

export default {
  components: {
    CartTotal,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BFormInput,
    FeatherIcon,
    BSpinner,
  },
  props: {
    cartProducts: {
      type: Array,
      default: () => [],
    },
    getCartProductsByBrand: {
      type: Function,
      default: () => {},
    },
    isBrandCartUpdated: {
      type: Number,
      default: 0,
    },
    isLoadingCart: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedRetailer: null,
      retailerId: null,
      reloadkey: 1,
      notesData: {},
      allDirectRetailers: [],
      searchText: '',
      textTruncate,
    }
  },
  computed: {
    ...mapState({
      directRetailers: state => state.connections.directRetailers || [],
      selectedDirectRetailer: state => state.shop.selectedDirectRetailer,
      selectedDirectRetailerTitle: state => (state.shop.selectedDirectRetailer
          ? `${state.shop.selectedDirectRetailer?.entityName} - ${state.shop.selectedDirectRetailer?.email}`
          : null),
    }),
  },
  watch: {
    searchText(val) {
      if (val) this.debouncedTrackSearchRetailer(val)
      this.searchRetailer()
    },
    isBrandCartUpdated() {
      this.reloadkey += 1
    },
    retailerId() {
      this.$emit('get-full-selected-products', {
        retailerId: this.retailerId || null,
        productIds: this.cartProducts.map(p => p._id) || [],
      })
    },
  },
  emits: ['close-brand-cart-modal', 'get-full-selected-products'],
  mounted() {
    this.allDirectRetailers = this.directRetailers
    if (this.selectedDirectRetailer) {
      this.onSelectRetailer(this.selectedDirectRetailer)
    }
  },
  methods: {
    async onSelectRetailer(retailer) {
      this.retailerId = retailer.retailerId
      this.selectedRetailer = retailer
      const discount = retailer?.discount || 1
      this.cartProducts.forEach(product => {
        product.discount = discount
      })
      this.searchText = ''
      this.reloadkey += 1
      analytics.track(
        TRACKS.ACTIONS.BRAND_SELECTS_RETAILER_FOR_ORDER,
        formatObject({
          retailerId: retailer.retailerId,
          retailerName: retailer.entityName,
        }),
      )
    },
    removeProduct(product) {
      const index = this.cartProducts.findIndex(p => p._id === product._id)
      if (index !== -1) {
        this.cartProducts.splice(index, 1)
        if (this.cartProducts.length === 0) {
          this.$emit('close-brand-cart-modal')
        }
      }
      analytics.track(
        TRACKS.ACTIONS.BRAND_REMOVES_PRODUCT_FROM_CART,
        formatObject({
          productId: product._id,
          productName: product.name,
        }),
      )
    },
    removeMultipleProducts(productIds) {
      productIds.forEach(productId => {
        const index = this.cartProducts.findIndex(p => p._id === productId)
        if (index !== -1) {
          this.cartProducts.splice(index, 1)
        }
      })
      if (this.cartProducts.length === 0) {
        this.$emit('close-brand-cart-modal')
      }
    },
    updateNotes(notesData = {}) {
      this.notesData = {
        notes: notesData.notes,
        isEditNotes: notesData.isEditNotes,
      }
    },
    debouncedTrackSearchRetailer: debounce(searchText => {
      analytics.track(TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_SEARCHES_FOR_RETAILER, {
        searchText,
      })
    }, 500),
    searchRetailer() {
      this.allDirectRetailers = searchDirectRetailer(
        this.directRetailers,
        this.searchText,
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.direct-retailer-selected {
  gap: 0.5rem;
}
</style>
