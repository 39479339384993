import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { debounce } from 'lodash'

export const useCartProductTrackers = ({
  isBrand,
  collectionId,
  products,
  props,
  cartId,
  userId,
}) => {
  const getTrackNameForRemoveProduct = (event, type) => {
    const trackNames = {
      brand: {
        remove_button_click: constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_CLICKS_REMOVE_PRODUCT_BUTTON,
        close_modal: constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_CLOSES_REMOVE_PRODUCT_MODAL,
        confirm_modal_button_click: constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_CLICKS_REMOVE_PRODUCT_CONFIRM_BUTTON,
        product_remove_success: constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_REMOVE_PRODUCT_SUCCESS,
        product_remove_failed: constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_REMOVE_PRODUCT_FAILED,
      },
      retailer: {
        remove_button_click: constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_CLICKS_REMOVE_PRODUCT_BUTTON,
        close_modal: constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_CLOSES_REMOVE_PRODUCT_MODAL,
        confirm_modal_button_click: constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_CLICKS_REMOVE_PRODUCT_CONFIRM_BUTTON,
        product_remove_success: constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_REMOVE_PRODUCT_SUCCESS,
        product_remove_failed: constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_REMOVE_PRODUCT_FAILED,
      },
    }

    return trackNames[type][event]
  }

  const trackRemoveProduct = (productId, event) => {
    const type = isBrand.value ? 'brand' : 'retailer'
    const trackName = getTrackNameForRemoveProduct(event, type)
    const data = {
      retailerId: props?.retailerId || userId || '',
      productId,
    }

    if (isBrand.value) {
      data.collectionId = props.cartProducts?.[0]?.collectionId ?? ''
    } else {
      data.cartId = userId
    }
    analytics.track(trackName, data)
  }

  const trackErrorPopupClicks = (error, event) => {
    if (isBrand.value) {
      const trackName = event === 'close' ? constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_CLOSES_VALIDATION_POPUP : constants.TRACKS.ACTIONS.ADD_TO_ORDER_MODAL.BRAND_CLICKS_VALIDATION_RESOLUTION_BUTTON
      analytics.track(trackName, {
        collectionId: collectionId.value,
        retailerId: props.retailerId || userId || '',
        products: products.value,
        error,
      })
    } else {
      const trackName = event === 'close' ? constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_CLOSES_VALIDATION_POPUP : constants.TRACKS.ACTIONS.ORDER_DETAILS_PAGE.RETAILER_CLICKS_VALIDATION_RESOLUTION_BUTTON
      analytics.track(trackName, {
        cartId,
        retailerId: props.retailerId || userId || '',
        products: products.value,
        error,
      })
    }
  }

  const debouncedTrackSearchProductsInput = debounce((trackName, searchInput, collectionId, retailerId, isBrand, cartData) => {
    analytics.track(trackName, {
      collectionId: isBrand ? collectionId : undefined,
      cartId: isBrand ? undefined : cartData?._id,
      retailerId: retailerId || cartData?.userId || '',
      searchInput,
    })
  }, 500)

  return {
    debouncedTrackSearchProductsInput,
    trackRemoveProduct,
    trackErrorPopupClicks,
  }
}
